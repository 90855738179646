<template>
  <div class="admin-edit-isbn">
    <error :err.sync="error" />
    <form-generator
      :elements="elements"
      :data="isbn"
      :handleUpdate="({ key }, v) => (isbn[key] = v)"
      v-if="render"
    />
  </div>
</template>

<script>
import isbnSchema from "@/validation-schemas/isbns";
import FormGenerator from "@/components/form-generator.vue";
import { mapState } from "vuex";

function initialState() {
  return {
    loading: false,
    error: null,
    render: true,
    progress: 0,
    books: [],
    isbn: {}
  };
}

export default {
  components: { FormGenerator },
  name: "admin-add-sales-import",
  props: {
    useIsbn: Object
  },
  data() {
    return initialState();
  },
  mounted() {
    this.$store.dispatch("ancillaries/getSalesChannels", {
      all: true
    });
    this.$store.dispatch("saleImports/getProcessorTypes");
    this.search();
    window.onbeforeunload = this.beforeUnload;
  },
  computed: {
    ...mapState("ancillaries", ["allSalesChannels"]),
    ...mapState("saleImports", ["processorTypes"]),
    elements() {
      return [
        {
          key: "book",
          label: "Assigned to Book",
          type: "multiselect",
          placeholder: "Enter text to search Title...",
          attrs: {
            loading: this.loading,
            multiple: false,
            options: (() => {
              return this.books;
            })(),
            trackBy: "id",
            label: "title"
          },
          events: {
            "search-change": this.search
          }
        },
        {
          key: "type",
          label: "isbn-type",
          type: "select",
          attrs: {
            options: ["epub", "print"].map(i => ({
              text: this.$t(i),
              value: i
            }))
          }
        },
        {
          text: "save",
          type: "button",
          className: "float-right mt-5 mt-sm-0",
          attrs: {
            loading: this.loading,
            block: this.isSmallScreen
          },
          events: {
            click: this.save
          }
        }
      ];
    }
  },
  methods: {
    async search(q) {
      this.loading = true;
      let params = {
        missing_isbn: true,
        limit: 1000
      };

      if (q) {
        params.search = q;
      }

      try {
        const res = await this.$store.dispatch("books/get", params);
        this.books = res.data.data;
      } catch (err) {
        console.error(err);
      }

      this.loading = false;
    },
    beforeUnload() {
      if (this.loading && this.progress === 100) {
        return "";
      }
    },
    async save() {
      this.error = null;
      const { isbn } = this;
      const validation = isbnSchema.validate(isbn, this.joiOptions);
      if (validation.error) {
        this.error = validation.error.message;
      } else {
        this.loading = true;
        try {
          await this.$store.dispatch("isbns/update", {
            ...isbn,
            book_id: isbn.book ? isbn.book.id : null
          });

          // Reset data
          this.$emit("edited", isbn);
        } catch (err) {
          console.error(err);
        }
        this.loading = false;
      }
    }
  },
  watch: {
    useIsbn(isbn) {
      this.isbn = Object.assign({}, isbn);
    }
  }
};
</script>

<style>
.admin-edit-isbn {
  min-height: 350px;
}
</style>
