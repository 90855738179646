<template>
  <div class="isbn-manager">
    <portal to="page-call-to-action">
      <ebp-button @click="open = true">{{ $t("add-isbns") }}</ebp-button>
    </portal>
    <advanced-data-table
      :headers="headers"
      :items="records"
      :limit="50"
      :loading-global="loading"
      :total-items="records.length"
      :search="false"
      :tabs="tabs"
      @tabClicked="handleTabClicked"
    >
      <template #cell(book)="{item}">
        {{ item.book ? item.book.title : $t("n-a") }}
      </template>

      <template #cell(assigned_at)="{item}">
        {{ item.assigned_at ? formatDate(item.assigned_at) : null }}
      </template>

      <!-- Actions -->
      <template #actions="{ item }">
        <b-button
          variant="primary"
          size="sm"
          @click="edit(item)"
          v-tooltip.top-center="$t('edit')"
        >
          <i class="bx bx-pencil"></i>
        </b-button>
        <ebp-button
          variant="danger"
          class="ml-1"
          size="sm"
          @click="unassign(item)"
          v-if="item.book_id !== null"
          v-tooltip.top-center="`Unassign ISBN`"
          ><i class="bx bx-x"></i
        ></ebp-button>
      </template>
    </advanced-data-table>

    <!-- Isbn add modal -->
    <ebp-modal :open.sync="open" :tabs="['add-isbns']">
      <template #error><error :err.sync="error"/></template>
      <template #add-isbns>
        <add-isbn @added="added" />
      </template>
    </ebp-modal>

    <!-- Isbn edit modal -->
    <ebp-modal :open.sync="showEdit" :tabs="['edit-isbn']">
      <template #error><error :err.sync="error"/></template>
      <template #edit-isbn>
        <edit-isbn :useIsbn="editedItem || {}" @edited="edited" />
      </template>
    </ebp-modal>
  </div>
</template>

<script>
import AdvancedDataTable from "@/components/advanced-data-table";
import AddIsbn from "./Add";
import EditIsbn from "./Edit";

export default {
  name: "admin-languages",
  components: {
    AdvancedDataTable,
    AddIsbn,
    EditIsbn
  },
  data() {
    return {
      loading: false,
      records: [],
      type: "assigned-isbn",
      headers: ["isbn", "type", "book", "assigned_at"],
      open: false,
      error: null,
      editedItem: null,
      showEdit: false,
      tabs: ["assigned-isbn", "unassigned-isbn"]
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    handleTabClicked(item) {
      this.type = item;
      this.get();
    },
    async get() {
      this.loading = true;
      const res = await this.$store.dispatch("isbns/get", {
        assigned: this.type === "assigned-isbn",
        sort_by: "updated_at",
        sort_order: true
      });
      this.records = res.data;

      this.loading = false;
    },
    added() {
      this.open = false;
      this.get();
    },
    edited() {
      this.showEdit = false;
      this.get();
    },
    edit(isbn) {
      this.editedItem = isbn;
      this.showEdit = true;
    },
    async unassign(isbn) {
      if (confirm("Are you sure you want to un-assign this ISBN")) {
        await this.$store.dispatch("isbns/unassign", isbn.id);
        this.get();
      }
    }
  }
};
</script>

<style></style>
